import React, {useEffect, useState} from "react";
import {useAppSelector} from "../hooks/redux-hooks";
import {useSelectedPeriod} from "../hooks/selected-period-hook";
import {useAggregationPeriod} from "../hooks/aggregation-period-hook";
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import {BarChart} from "@mui/x-charts-pro";
import {createDataset, Data} from "./dataSetBuilder";

const chartSetting = {
    yAxis: [
        {
            label: 'Count',
        },
    ],
    series: [{dataKey: 'count', label: 'Count', valueFormatter: (value: number | null) => `${value} times`}],
    sx: {
        [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
            transform: 'translateX(-10px)',
        },
    },
};


const Chart = () => {
    const [dataset, setDataset] = useState<Data[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const events = useAppSelector((state) => state.event.events);
    const [selectedPeriod] = useSelectedPeriod()
    const [aggregationPeriod] = useAggregationPeriod()

    useEffect(() => {
        setLoadingData(true);

        if (selectedPeriod.from == null || selectedPeriod.to == null) {
            setDataset([]);
            return;
        }
        if (selectedPeriod.from.isAfter(selectedPeriod.to)) {
            setDataset([]);
            return;
        }

        setDataset(createDataset([...events], selectedPeriod, aggregationPeriod))
        setLoadingData(false);
    }, [events, selectedPeriod, aggregationPeriod]);


    return (
        <>
            <BarChart
                height={350}
                loading={loadingData}
                dataset={dataset}
                xAxis={[
                    {scaleType: 'band', dataKey: 'date', tickPlacement: 'extremities', tickLabelPlacement: 'middle'},
                ]}
                {...chartSetting}
            />
        </>
    )
}


export default Chart