import {LockOutlined} from "@mui/icons-material";
import {Avatar, Box, Container, CssBaseline, Grid2, TextField, Typography,} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../hooks/redux-hooks";
import {register} from "../slices/authSlice";
import Header from "./Header";
import Alert from '@mui/material/Alert';


const Register = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [showAlertMsg, setShowAlertMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async () => {
        setShowAlertMsg("");
        if (username && password) {
            setIsLoading(true);
            dispatch(register({username, password})).unwrap()
                .then(() => {
                    navigate("/login", {state: {alertMessage: "Success registration", alertColor: "success"}});
                })
                .catch(e => {
                    setShowAlertMsg(e.message)
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            setShowAlertMsg("Username and password should be filled")
        }
    };

    return (
        <>
            <Header logoutEnabled={false}/>
            <Container maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        mt: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: "primary.light"}}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography variant="h5">Register</Typography>
                    {showAlertMsg ? <Alert severity="error">{showAlertMsg}</Alert> : null}
                    <Box sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />

                        <LoadingButton
                            onClick={handleRegister}
                            loading={isLoading}
                            loadingPosition="center"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                            Register
                        </LoadingButton>
                        <Grid2 container justifyContent={"flex-end"}>
                            <Grid2>
                                <Link to="/login">Already have an account? Login</Link>
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Register;