import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import {useAppSelector} from "../hooks/redux-hooks";

const ProtectedLayout = () => {
    const token = useAppSelector((state) => state.auth.token);

    if (!token) {
        delete axiosInstance.defaults.headers.common["Authorization"];
        return <Navigate replace to={"/login"}/>;
    }

    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    return (
        <>
            <Outlet/>
        </>
    );
};

export default ProtectedLayout;
