import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";

export type Event = {
    caseId: string,
    id?: string,
    timestamp?: string
};

type EventApiState = {
    events: Event[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

const initialState: EventApiState = {
    events: [],
    status: "idle",
    error: null,
};

export const getEvents = createAsyncThunk("getEvents", async (caseId: string) => {
    const response = await axiosInstance.get<Event[]>("/event/" + caseId);
    return response.data;
});

export const registerEvent = createAsyncThunk("registerEvent", async (event: Event) => {
    const response = await axiosInstance.post<Event>("/event", event);
    return response.data;
});

export const deleteEvent = createAsyncThunk("deleteEvent", async (event: Event) => {
    const response = await axiosInstance.delete("/event/" + event.id);
    return response.data;
});

const eventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getEvents.fulfilled, (state, action: PayloadAction<Event[]>) => {
                state.status = "idle";
                state.events = action.payload;
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Get events failed";
            })

            .addCase(registerEvent.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(registerEvent.fulfilled, (state, action: PayloadAction<Event>) => {
                state.status = "idle";
                state.events = [...state.events, action.payload];
            })
            .addCase(registerEvent.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Get events failed";
            })

            .addCase(deleteEvent.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deleteEvent.fulfilled, (state, action: PayloadAction<Event>) => {
                state.status = "idle";
                state.events = [...state.events].filter(e => e.id !== action.payload.id);
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Get events failed";
            })
    },
});

export default eventSlice.reducer;