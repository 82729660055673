import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import aCaseReducer from "./slices/aCaseReducer";
import eventReducer from "./slices/eventReducer";

const store = configureStore({
    reducer: {
        auth: authReducer,
        aCase: aCaseReducer,
        event: eventReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
