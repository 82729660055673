import React, {useEffect, useState} from "react";
import {IconButton, Menu, MenuItem, styled} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimeRangePicker} from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import {SingleInputDateTimeRangeField} from '@mui/x-date-pickers-pro/SingleInputDateTimeRangeField';
import Stack from "@mui/material/Stack";
import {getPeriod, Period, useSelectedPeriod} from "../../hooks/selected-period-hook";
import {useAggregationPeriod} from "../../hooks/aggregation-period-hook";
import {AggregationPeriod} from "./AggregationPeriodBtn";
import {ArrowLeftIcon, ArrowRightIcon} from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";


export enum PredefinedPeriod {
    CurrentDay = "Current Day",
    CurrentWeek = "Current Week",
    CurrentMonth = "Current Month",
    CurrentYear = "Current Year"
}

const Div = styled('div')(({theme}) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing("4px"),
    paddingRight: theme.spacing("8px")
}));

const CustomizedDateTimeRangePicker = styled(DateTimeRangePicker)`
    & .MuiInputBase-input {
        font-size: 7px;
    }
`;

const PeriodSelector = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedPeriod, setSelectedPeriod] = useSelectedPeriod()
    const [aggregationPeriod, setAggregationPeriod] = useAggregationPeriod()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (predefinedPeriod?: PredefinedPeriod) => {
        if (predefinedPeriod) {
            let selectedPeriod = getPeriod(predefinedPeriod);
            checkAndSetPeriod(selectedPeriod)
        }

        setAnchorEl(null);
    };

    const checkAndSetPeriod = ({from, to}: Period) => {
        let diff = to.diff(from, 'day')
        if (diff > 367) {
            if (aggregationPeriod === AggregationPeriod.Hour || aggregationPeriod === AggregationPeriod.Day) {
                setAggregationPeriod(AggregationPeriod.Month)
            }
        } else if (diff > to.daysInMonth() + 1) {
            if (aggregationPeriod === AggregationPeriod.Hour) {
                setAggregationPeriod(AggregationPeriod.Day)
            }
        }

        setSelectedPeriod({from, to})
    }

    const prevPeriod = () => {
        let diff = selectedPeriod.to.diff(selectedPeriod.from, 'milliseconds');
        setSelectedPeriod({
            from: selectedPeriod.from.subtract(diff, 'milliseconds'),
            to: selectedPeriod.from
        })
    }

    const nextPeriod = () => {
        let diff = selectedPeriod.to.diff(selectedPeriod.from, 'milliseconds');
        setSelectedPeriod({
            from: selectedPeriod.to,
            to: selectedPeriod.to.add(diff, 'milliseconds')
        })
    }

    const isNextPeriodDisabled = (): boolean => {
        let diff = selectedPeriod.to.diff(selectedPeriod.from, 'milliseconds');
        let nextPeriodFrom = selectedPeriod.from.add(diff, 'milliseconds')
        return nextPeriodFrom.isAfter(dayjs(new Date))
    }

    const [nextPeriodDisabled, setNextPeriodDisabled] = useState<boolean>(() => isNextPeriodDisabled());

    useEffect(() => {
        setNextPeriodDisabled(isNextPeriodDisabled());
    }, [selectedPeriod]);

    return (
        <Stack sx={{display: 'flex', borderColor: 'grey', border: 1, borderRadius: 3, padding: 1, width: "250px"}}
               direction="column" justifyContent="center" alignItems={"center"}>
            <Div>Select period:</Div>
            <Box sx={{display: 'flex', width: "250px", paddingBottom: "10px"}}>
                <IconButton onClick={prevPeriod}>
                    <ArrowLeftIcon/>
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomizedDateTimeRangePicker
                        onAccept={value => {
                            checkAndSetPeriod({
                                from: value[0] || selectedPeriod.from,
                                to: value[1] || selectedPeriod.to
                            })
                        }}
                        ampm={false}
                        timezone={'system'}
                        value={[selectedPeriod.from, selectedPeriod.to]}
                        sx={{width: "250px"}}
                        slots={{field: SingleInputDateTimeRangeField}}/>
                </LocalizationProvider>
                <IconButton onClick={nextPeriod} disabled={nextPeriodDisabled}>
                    <ArrowRightIcon/>
                </IconButton>
            </Box>
            <Box sx={{display: 'flex', width: "250px"}} height={"30px"}>
                <Div sx={{width: "150px"}}>Predefined period:</Div>
                <Button
                    sx={{width: "90px"}}
                    endIcon={<KeyboardArrowDownIcon/>}
                    variant="contained"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>SELECT</Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose()}
                    MenuListProps={{'aria-labelledby': 'basic-button',}}>
                    <MenuItem onClick={() => handleClose(PredefinedPeriod.CurrentDay)}>Current day</MenuItem>
                    <MenuItem onClick={() => handleClose(PredefinedPeriod.CurrentWeek)}>Current week</MenuItem>
                    <MenuItem onClick={() => handleClose(PredefinedPeriod.CurrentMonth)}>Current month</MenuItem>
                    <MenuItem onClick={() => handleClose(PredefinedPeriod.CurrentYear)}>Current year</MenuItem>
                </Menu>
            </Box>
        </Stack>
    );
}

export default PeriodSelector;