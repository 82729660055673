import React, {useState} from "react";
import Button from "@mui/material/Button";
import {DateTimePicker} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import dayjs, {Dayjs} from "dayjs";
import {registerEvent} from "../slices/eventReducer";
import {useAppDispatch} from "../hooks/redux-hooks";

type AddEventPaneProps = {
    caseId?: string
}

const AddEventPane = (props: AddEventPaneProps) => {
    const dispatch = useAppDispatch();

    const [selectedTime, setSelectedTime] = useState<Dayjs>(dayjs(new Date()));

    const handleRegistration = () => {
        if (!props.caseId) {
            return;
        }

        dispatch(registerEvent({caseId: props.caseId, timestamp: selectedTime.toISOString()})).unwrap()
            .catch(e => {
                console.log(e);
            })
    }

    return (
        <Stack sx={{height: "400px"}} direction="column" justifyContent="center" alignItems={"center"}>
            <Button onClick={handleRegistration} variant={"contained"}
                    sx={{borderRadius: '100%', width: 100, height: 100, margin: "20px"}}>DO</Button>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    ampm={false}
                    timezone={'system'}
                    onChange={(e) => e && setSelectedTime(e)}
                    disableFuture={true} sx={{width: "240px"}} label="Retroactively (optional)"/>
            </LocalizationProvider>
        </Stack>
    );
};

export default AddEventPane;