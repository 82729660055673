import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/redux-hooks";
import {deleteEvent, Event, getEvents} from "../slices/eventReducer";
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {CircularProgress, IconButton} from "@mui/material";

type EventsTableProps = {
    caseId?: string
}

const columns: GridColDef[] = [
    {field: 'id'},
    {field: 'timestamp', headerName: "Timestamp", flex: 9, disableColumnMenu: true},
    {
        field: 'delete',
        headerName: "",
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        align: "center",
        renderCell: (params) => <DeleteEventButton event={params.row}/>
    }
];

const DeleteEventButton = (props: { event: Event }) => {
    const dispatch = useAppDispatch();

    const [confirmState, setConfirmState] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        if (!confirmState) {
            setConfirmState(true);
        } else {
            setLoading(true)
            dispatch(deleteEvent(props.event)).unwrap()
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    };

    return (
        <IconButton onClick={handleDelete} disabled={loading}>
            {loading ? <CircularProgress size={24}/> : confirmState ? <WarningIcon color={"warning"}/> : <DeleteIcon/>}
        </IconButton>
    )
}

const EventsTable = (props: EventsTableProps) => {
    const dispatch = useAppDispatch();
    const rows = useAppSelector((state) => state.event.events);
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});

    useEffect(() => {
        if (props.caseId) {
            dispatch(getEvents(props.caseId)).unwrap()
                .catch(e => {
                    console.log(e);
                })
        }
    }, [props.caseId]);

    return (
        <Paper sx={{height: 400, width: '100%'}}>
            <DataGrid
                rowHeight={38}
                disableColumnResize={true}
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false
                        }
                    },
                    sorting: {
                        sortModel: [
                            {field: "timestamp", sort: "desc"}
                        ]
                    },
                    pagination: {paginationModel}
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                rowSelection={false}
                sx={{border: 0}}
            />
        </Paper>
    );
};

export default EventsTable;