import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DefaultLayout from "./layouts/DefaultLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";

function App() {
    return (
        <>
            <Routes>
                <Route element={<DefaultLayout/>}>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                </Route>
                <Route element={<ProtectedLayout/>}>
                    <Route path="/" element={<Home/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;