import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/redux-hooks";
import {ACase, createCase, deleteCase, getCases} from "../slices/aCaseReducer";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid2,
    IconButton,
    Tab,
    TextField
} from "@mui/material";
import EventsTable from "./EventsTable";
import AddEventPane from "./AddEventPane";
import {GridAddIcon} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ChartPane from "./ChartPane";

const MainPanel = () => {
        const dispatch = useAppDispatch();
        const cases = useAppSelector((state) => state.aCase.aCases);
        const [selectedCase, setSelectedCase] = useState<ACase | undefined>(undefined);
        const [caseToDelete, setCaseToDelete] = useState<ACase | undefined>(undefined);
        const [addNewOpen, setAddNewOpen] = useState<boolean>(false);
        const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

        const [activeTab, setActiveTab] = React.useState(0);

        useEffect(() => {
            dispatch(getCases()).unwrap()
                .then((cases: ACase[]) => {
                    setSelectedCase(cases ? cases[0] : undefined)
                })
                .catch(e => {
                    console.log(e);
                })
        }, []);

        const handleAddClick = () => {
            setAddNewOpen(true);
        }

        const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
            if (newValue !== cases.length) {
                setActiveTab(newValue);
                setSelectedCase(cases[newValue]);
            } else {
                handleAddClick();
            }
        };

        const handleAddNew = (event: React.FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const name = formJson.name;


            // TODO: Handle errors, loader and error message
            dispatch(createCase({name: name})).unwrap()
                .then((aCase: ACase) => {
                    setActiveTab(cases.length)
                    setSelectedCase(aCase)
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    closeAddNew();
                })
        }

        const closeAddNew = (): void => setAddNewOpen(false);
        const closeDelete = (): void => setDeleteOpen(false);

        const performDelete = (isDelete: boolean): void => {
            if (isDelete && caseToDelete) {
                dispatch(deleteCase(caseToDelete)).unwrap()
                    .then(e => {
                        if (caseToDelete === selectedCase) {
                            setActiveTab(0)
                            setSelectedCase(cases[0])
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        closeDelete()
                        setCaseToDelete(undefined)
                    })
            } else {
                closeDelete()
                setCaseToDelete(undefined)
            }
        }

        return (
            <>
                <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                    <Tabs value={activeTab} onChange={handleTabChange} centered>
                        {cases.map(aCase => {
                            return <Tab key={aCase.id} label={
                                <span>
                                {aCase.name}
                                    <IconButton size="small" component="span" onClick={(e) => {
                                        setCaseToDelete(aCase)
                                        setDeleteOpen(true)
                                    }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </span>

                            }/>
                        })}
                        <Tab icon={<GridAddIcon/>} onClick={handleAddClick} key={"add"}/>
                    </Tabs>
                    <Divider/>
                    {cases.length > 0 &&
                        <>
                            <Grid2 container spacing={2} columns={2}>
                                <Grid2 size={1}>
                                    <AddEventPane caseId={selectedCase?.id}/>
                                </Grid2>
                                <Grid2 size={1}>
                                    <EventsTable caseId={selectedCase?.id}/>
                                </Grid2>
                            </Grid2>
                            <Divider/>
                            <ChartPane/>
                        </>}
                </Box>
                <Dialog
                    open={addNewOpen}
                    onClose={closeAddNew}
                    PaperProps={{component: 'form', onSubmit: handleAddNew}}>
                    <DialogTitle>Add new counter</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAddNew}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={deleteOpen}
                    onClose={closeDelete}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        Delete case {caseToDelete?.name} ?
                    </DialogTitle>
                    <DialogActions>
                        <Button autoFocus onClick={() => performDelete(false)}>
                            No
                        </Button>
                        <Button onClick={() => performDelete(true)} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
;

export default MainPanel;