import {LockOutlined} from "@mui/icons-material";
import {AlertColor, Avatar, Box, Container, CssBaseline, Grid2, TextField, Typography,} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useAppDispatch} from "../hooks/redux-hooks";
import {login} from "../slices/authSlice";
import Header from "./Header";
import Alert from '@mui/material/Alert';

const Login = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [alert, setAlert] = useState<{ msg: string, color: AlertColor | undefined }>({msg: "", color: undefined});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (location?.state?.alertMessage) {
            setAlert({msg: location.state.alertMessage, color: location?.state?.alertColor});
        }
    }, [location])

    const handleLogin = async () => {
        setAlert({msg: "", color: undefined});
        if (username && password) {
            setIsLoading(true);
            dispatch(login({username, password})).unwrap()
                .catch(e => {
                    setAlert({msg: e.message, color: "error"});
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            setAlert({msg: "Username and password should be filled", color: "error"});
        }
    };

    return (
        <>
            <Header logoutEnabled={false}/>
            <Container maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        mt: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: "primary.light"}}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography variant="h5">Login</Typography>
                    {alert.msg ? <Alert severity={alert.color}>{alert.msg}</Alert> : null}
                    <Box sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />

                        <LoadingButton
                            onClick={handleLogin}
                            loading={isLoading}
                            loadingPosition="center"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                            Login
                        </LoadingButton>
                        <Grid2 container justifyContent={"flex-end"}>
                            <Grid2>
                                <Link to="/register">Don't have an account? Register</Link>
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Login;