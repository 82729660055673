import {useLocalStorage} from "usehooks-ts";
import dayjs, {OpUnitType} from "dayjs";
import {PredefinedPeriod} from "../pages/components/PeriodSelector";
import {Dispatch, SetStateAction} from "react";

export type Period = {
    from: dayjs.Dayjs,
    to: dayjs.Dayjs
}

export const getPeriod = (period: PredefinedPeriod): Period => {
    let unit: OpUnitType = 'month'; // By default
    if (period === PredefinedPeriod.CurrentDay) {
        unit = 'day';
    } else if (period === PredefinedPeriod.CurrentWeek) {
        unit = 'week';
    } else if (period === PredefinedPeriod.CurrentMonth) {
        unit = 'month';
    } else if (period === PredefinedPeriod.CurrentYear) {
        unit = 'year';
    }    return {
        from: dayjs().startOf(unit),
        to: dayjs().add(1, unit).startOf(unit)
    }
}

export const useSelectedPeriod = (): [Period, Dispatch<SetStateAction<Period>>, () => void] => {
    return useLocalStorage("selectedPeriod", getPeriod(PredefinedPeriod.CurrentMonth), {
            serializer: value => {
                return JSON.stringify({
                    from: value.from.format(),
                    to: value.to.format()
                })
            },
            deserializer: value => {
                let parsed = JSON.parse(value)
                return {
                    from: dayjs(parsed.from),
                    to: dayjs(parsed.to)
                }
            }
        }
    )
}