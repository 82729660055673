import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import {useAppSelector} from "../hooks/redux-hooks";

const DefaultLayout = () => {
    const token = useAppSelector((state) => state.auth.token);

    if (token) {
        axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
        return <Navigate replace to={"/"}/>;
    }

    delete axiosInstance.defaults.headers.common["Authorization"];
    return (
        <>
            <Outlet/>
        </>
    );
};

export default DefaultLayout;