import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import {deleteEvent, Event} from "./eventReducer";

type ACaseApiState = {
    aCases: ACase[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

export type ACase = {
    id: string,
    name: string
};

const initialState: ACaseApiState = {
    aCases: [],
    status: "idle",
    error: null,
};

export const getCases = createAsyncThunk("getCases", async () => {
    const response = await axiosInstance.get("/case");
    return response.data;
});

export const createCase = createAsyncThunk("createCase", async (payload: { name: string }) => {
    const response = await axiosInstance.post("/case", payload);
    return response.data;
});

export const deleteCase = createAsyncThunk("deleteCase", async (payload: ACase) => {
    const response = await axiosInstance.delete("/case/" + payload.id);
    return response.data;
});

const aCaseSlice = createSlice({
    name: "aCase",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCases.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getCases.fulfilled, (state, action: PayloadAction<ACase[]>) => {
                state.status = "idle";
                state.aCases = action.payload;
            })
            .addCase(getCases.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Get cases failed";
            })


            .addCase(createCase.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createCase.fulfilled, (state, action: PayloadAction<ACase>) => {
                state.status = "idle";
                state.aCases = [...state.aCases, action.payload];
            })
            .addCase(createCase.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Get events failed";
            })

            .addCase(deleteCase.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deleteCase.fulfilled, (state, action: PayloadAction<ACase>) => {
                state.status = "idle";
                state.aCases = [...state.aCases].filter(e => e.id !== action.payload.id);
            })
            .addCase(deleteCase.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Get events failed";
            })
    },
});

export default aCaseSlice.reducer;