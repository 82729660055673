import Header from "./Header";
import React from "react";
import MainPanel from "./MainPanel";

const Home = () => {
    return (
        <>
            <Header logoutEnabled={true}/>
            <MainPanel/>
        </>
    );
};

export default Home;