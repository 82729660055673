import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";

type User = {
    username: string;
    password: string;
};

type AuthApiState = {
    token?: string | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

const initialState: AuthApiState = {
    token: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null,
    status: "idle",
    error: null,
};

export const login = createAsyncThunk("login", async (data: User) => {
    const response = await axiosInstance.post("/auth/login", data);
    let res = response.data;
    localStorage.setItem("token", res);
    return res;
});

export const register = createAsyncThunk("register", async (data: User) => {
    const response = await axiosInstance.post("/auth/register", data);
    return response.data;
});

export const logout = createAsyncThunk("logout", async () => {
    localStorage.removeItem("token");
});

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
                state.status = "idle";
                state.token = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Login failed";
            })
            .addCase(register.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(register.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
            })
            .addCase(register.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Registration failed"; // TODO: Сюда не приходит ошибка с бэка
            })
            .addCase(logout.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.status = "idle";
                state.token = null;
            })
            .addCase(logout.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Logout failed";
            })
        ;
    },
});

export default authSlice.reducer;