import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Menu, MenuItem, styled} from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useAggregationPeriod} from "../../hooks/aggregation-period-hook";
import {useSelectedPeriod} from "../../hooks/selected-period-hook";

export enum AggregationPeriod {
    Hour = "Hour",
    Day = "Day",
    Week = "Week",
    Month = "Month",
    Year = "Year"
}

const Div = styled('div')(({theme}) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing("4px"),
    paddingRight: theme.spacing("8px"),
    width: "150px"
}));

const AggregationPeriodButton = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [aggregationPeriod, setAggregationPeriod] = useAggregationPeriod()
    const [selectedPeriod] = useSelectedPeriod()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (period?: AggregationPeriod) => {
        if (period) {
            setAggregationPeriod(period)
        }
        setAnchorEl(null);
    };

    const isDisabled = (value: AggregationPeriod): boolean => {
        let diff = selectedPeriod.to.diff(selectedPeriod.from, 'day')
        if ((diff > selectedPeriod.to.daysInMonth() + 1) && value === AggregationPeriod.Hour ) {
            return true
        } else if (diff > 367 && (value === AggregationPeriod.Hour || value === AggregationPeriod.Day)) {
            return true
        }

        return false
    }

    return (
        <Box sx={{display: 'flex', borderColor: 'black', border: 1, borderRadius: 3, padding: 1, width: "250px"}}
             height={"30px"}>
            <Div>Aggregate by:</Div>
            <Button
                sx={{width: "120px"}}
                endIcon={<KeyboardArrowDownIcon/>}
                variant="contained"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>{aggregationPeriod}</Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{'aria-labelledby': 'basic-button',}}>
                <MenuItem disabled={isDisabled(AggregationPeriod.Hour)} onClick={() => handleClose(AggregationPeriod.Hour)}>Hour</MenuItem>
                <MenuItem disabled={isDisabled(AggregationPeriod.Day)} onClick={() => handleClose(AggregationPeriod.Day)}>Day</MenuItem>
                <MenuItem onClick={() => handleClose(AggregationPeriod.Week)}>Week</MenuItem>
                <MenuItem onClick={() => handleClose(AggregationPeriod.Month)}>Month</MenuItem>
                <MenuItem onClick={() => handleClose(AggregationPeriod.Year)}>Year</MenuItem>
            </Menu>
        </Box>
    );
}

export default AggregationPeriodButton;