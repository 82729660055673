import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AggregationPeriodBtn from "./components/AggregationPeriodBtn";
import PeriodSelector from "./components/PeriodSelector";
import Chart from "./Chart";

const ChartPane = () => {
    return (
        <Box sx={{display: 'flex'}}>
            <Stack sx={{width: "350px"}} direction="column" justifyContent="center" alignItems={"center"}>
                <PeriodSelector/>
                <br/>
                <AggregationPeriodBtn/>
            </Stack>
            <Chart/>
        </Box>
    );
};

export default ChartPane;