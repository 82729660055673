import {Event} from "../slices/eventReducer";
import {Period} from "../hooks/selected-period-hook";
import {AggregationPeriod} from "./components/AggregationPeriodBtn";
import dayjs, {OpUnitType} from "dayjs";

export type Data = {
    count: number,
    date: string,
}

export const createDataset = (origEvents: Event[], selectedPeriod: Period, aggregationPeriod: AggregationPeriod): Data[] => {
    let events = origEvents
        .filter(event => {
            let timeStamp = dayjs(event.timestamp)
            return selectedPeriod.from.isBefore(timeStamp) && selectedPeriod.to.isAfter(timeStamp)
        })
        .sort((a, b) => dayjs(a.timestamp).unix() - dayjs(b.timestamp).unix())

    return createDataWithPeriods(events, selectedPeriod, aggregationPeriod);
}

const createDataWithPeriods = (events: Event[], selectedPeriod: Period, aggregationPeriod: AggregationPeriod): Data[] => {
    let unit = aggregationPeriodToUnit(aggregationPeriod)

    let firstPeriod = selectedPeriod.from.startOf(unit)
    let lastPeriod = selectedPeriod.to.startOf(unit).subtract(1, unit)

    let format = aggregationPeriodToFormat(aggregationPeriod)
    let dataMap: Map<string, Data> = new Map()
    let currentPeriod = firstPeriod;

    while (!currentPeriod.isAfter(lastPeriod)) {
        let date = currentPeriod.format(format)
        dataMap.set(date, {count: 0, date: date})
        currentPeriod = currentPeriod.add(1, unit)
    }

    events.forEach(event => {
        let key = dayjs(event.timestamp).startOf(unit).format(format)
        dataMap.get(key)!.count++;
    })

    return Array.from(dataMap.values());
}

const aggregationPeriodToUnit = (aggregationPeriod: AggregationPeriod): OpUnitType => {
    switch (aggregationPeriod) {
        case AggregationPeriod.Hour:
            return 'hour';
        case AggregationPeriod.Day:
            return 'day';
        case AggregationPeriod.Week:
            return 'week';
        case AggregationPeriod.Month:
            return 'month';
        case AggregationPeriod.Year:
            return 'year';
    }
}

const aggregationPeriodToFormat = (aggregationPeriod: AggregationPeriod): string => {
    switch (aggregationPeriod) {
        case AggregationPeriod.Hour:
            return 'HH DD/MMM/YYYY';
        case AggregationPeriod.Day:
            return 'DD/MMM/YYYY';
        case AggregationPeriod.Week:
            return 'DD/MMM/YYYY';
        case AggregationPeriod.Month:
            return 'MMM/YYYY';
        case AggregationPeriod.Year:
            return 'YYYY';
    }
}


