import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useAppDispatch} from "../hooks/redux-hooks";
import {login, logout} from "../slices/authSlice";
import LoadingButton from "@mui/lab/LoadingButton";

interface HeaderProps {
    logoutEnabled: boolean
}

const Header = (props: HeaderProps) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const logoutHandler = async () => {
        setIsLoading(true);
        dispatch(logout()).unwrap()
            .catch(e => {
                // TODO
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Counter
                    </Typography>
                    {props.logoutEnabled ?
                        <LoadingButton
                            onClick={logoutHandler}
                            loading={isLoading}
                            loadingPosition="center"
                            // fullWidth
                            variant="contained"
                            // sx={{mt: 3, mb: 2}}
                        >
                            Logout
                        </LoadingButton>
                        : null}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;